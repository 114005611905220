import { Question } from '@/types/api/Question';
import { apiExecuteGetHandled } from '@/services/api';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export async function getQuestionList(
  quizId: number,
  language: string | null = null
): Promise<Question[]> {
  const response = await apiExecuteGetHandled<Question[]>(
    `/question_list/${quizId}?lang=${language}`,
    []
  );

  return response.map((item) => {
    return {
      id: item.id,
      quiz: quizId,
      title: item.title,
      description: item.description,
      hint: item.hint,
      explanation: item.explanation,
      result: '',
    };
  });
}
