import vehicles from '@/assets/data/vehicle.json';
import { VehicleType } from '@/types/enum/VehicleType';
import { Answer } from '@/types/api/Answer';
import { apiExecuteGetHandled } from '@/services/api';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export interface apiAnswer {
  answer: string;
  correct: string;
}

export async function getAnswerList(
  questionId: number,
  language: string | null = null
): Promise<Answer[]> {
  const response = await apiExecuteGetHandled<apiAnswer[]>(
    `/answer_list/${questionId}?lang=${language}`,
    []
  );

  return response.map((item, index) => {
    let vehicleType = VehicleType.SHIP;
    switch (index) {
      case 0:
        vehicleType = VehicleType.SHIP;
        break;
      case 1:
        vehicleType = VehicleType.TRAIN;
        break;
      case 2:
        vehicleType = VehicleType.TRUCK;
        break;
    }
    return {
      id: index,
      question: questionId,
      title: item.answer,
      vehicle: vehicleType,
      description: '',
      correct: item.correct === '1',
      settings: vehicleType ? { icon: vehicles[vehicleType].icon } : {},
    };
  });
}
