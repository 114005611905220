import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bac3f2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visual_progress = _resolveComponent("visual-progress")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_quiz_question = _resolveComponent("quiz-question")!

  return (_openBlock(), _createBlock(_component_quiz_question, {
    question: _ctx.question,
    answers: _ctx.answers,
    showHint: _ctx.showHint,
    showInfo: _ctx.showInfo,
    isCorrect: _ctx.isCorrect,
    "question-no": _ctx.questionNo,
    "state-list": _ctx.stateList,
    "can-confirm": _ctx.canConfirm,
    onConfirm: _ctx.confirm
  }, {
    header: _withCtx(() => [
      (_ctx.quiz)
        ? (_openBlock(), _createBlock(_component_visual_progress, {
            key: 0,
            visualizationType: _ctx.visualizationType,
            "question-count": _ctx.quiz.settings.count,
            modelValue: _ctx.animate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.animate) = $event)),
            answerKey: _ctx.answerKey,
            "animate-final-step": _ctx.animateFinalStep,
            onAnimationCompleted: _ctx.animationCompleted,
            onFinalCompleted: _cache[1] || (_cache[1] = () => _ctx.$emit('finalCompleted'))
          }, null, 8, ["visualizationType", "question-count", "modelValue", "answerKey", "animate-final-step", "onAnimationCompleted"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (answer, index) => {
        return (_openBlock(), _createElementBlock("button", {
          key: answer.id,
          onClick: ($event: any) => (_ctx.clickAnswer(index))
        }, [
          (_ctx.choices[index].result)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: ['far', 'circle-check']
              }))
            : (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                icon: ['far', 'circle']
              })),
          (answer.settings.icon && _ctx.quiz.settings.show_icons)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 2,
                icon: answer.settings.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(answer.title) + " ", 1),
          (answer.description)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(answer.description), 1))
            : _createCommentVNode("", true)
        ], 8, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }, 8, ["question", "answers", "showHint", "showInfo", "isCorrect", "question-no", "state-list", "can-confirm", "onConfirm"]))
}