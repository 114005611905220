import { useCookies } from 'vue3-cookies';
import { VueI18n } from 'vue-i18n';
const { cookies } = useCookies();

const LOCALE = 'locale';

/* eslint-disable @typescript-eslint/no-explicit-any*/

export const setLocale = (
  locale: string,
  i18n: VueI18n | null = null
): void => {
  if (cookies) cookies.set(LOCALE, locale);
  if (i18n) i18n.locale = locale as any;
};

export const getLocale = (): string => {
  if (cookies) return cookies.get(LOCALE);
  return '';
};
