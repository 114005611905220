import { Choice } from '@/types/api/Choice';

export async function postChoice(choice: Choice): Promise<Choice> {
  //console.log('post', choice);
  return choice;
}

export async function putChoice(choice: Choice): Promise<Choice> {
  //console.log('put', choice);
  return choice;
}
